<template>
    <div class="options">
        <div class="options__top">
            <div class="options__count" @click="showDropdown"  v-if="(data.accessories_count || 0) + (data.additional_options_count || 0)">
                <span>+{{ (data.accessories_count || 0)  + (data.additional_options_count || 0)}} available options</span> <svg-icon-qouting class="chevron" data="fas fa-chevron-down" />
            </div>
        </div>
         <div v-if="show" class="options__dropdown tw-max-h-64 tw-overflow-auto">
             <Loader class="dropdown__loader" v-if="!data.options || !data.options.length"/>
            <div class="dropdrown__item" v-for="(item, index) in data.options" :key="index">
              <div class="" v-if="typeof(Object.values(item)[0]) === 'object'">
                <strong>{{ Object.keys(item)[0] }}</strong>
                <ul>
                  <li v-for="(v, i) in Object.values(Object.values(item)[0])" :key="i">
                    • {{v}}
                  </li>
                </ul>
              </div>
              <div v-else>
                <strong>{{ Object.keys(item)[0] }}</strong><br> <span v-html="Object.values(item)[0]"></span><hr/>
              </div>
            </div>
        </div>

    </div>
</template>

<script>
import  { ref } from 'vue'
import { useFormatter } from '../../../../composables/formatter'
import api from '../../../../services/item.service'
import Loader from '../../../elements/Loader.vue'

export default ({
    components: {
        Loader
    },
    props: ['data', 'area'],
    setup(props, { emit }) {
        const formatter = useFormatter()
        const show = ref(false)

        function showDropdown() {
            if(!props.data.options && ((props.data.accessories_count || 0) + (props.data.additional_options_count || 0))) getOptions()
            show.value = !show.value
        }

        async function getOptions() {
            const result = await api.getItemAcessories(props.data.id, props.area)
            props.data.options = result
        }

        return {
            showDropdown,
            show,
            formatter
        }
    },
})
</script>

<style lang="scss" scoped>
.options {
    font-size: 13px;
    line-height: 100%;
    width: 100%;
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        width: 100%;
    }
    &__dropdown {
        text-align: left;
        display: flex;
        flex-direction: column;
    }
    &__price {
        margin-left: auto;
    }
    &__count {
        color: rgba(89, 182, 146, 0.8);
        cursor: pointer;
        display: flex;
        align-items: center;
        span {
            border-bottom: 1px dashed rgba(89, 182, 146, 0.8);
        }
        .chevron {
            font-size: 10px;
            font-weight: bold;
            margin-left: 3px;
        }

    }
}
.dropdrown {
    &__item {
      font-size: 0.95em;
        margin-top: 5px;
    }
}
::v-deep .spinner.dropdown__loader svg {
    font-size: 25px!important;
    margin-top: 15px;
}

</style>
