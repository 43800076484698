<script>

import { onMounted, ref } from 'vue'

export default ({
    setup(props, {emit}) {
        const el = ref(null)
        onMounted(() => {
                let observer = new IntersectionObserver(
                    (entries) => {
                        if (entries[0].isIntersecting) {
                            const panel = entries[0].boundingClientRect
                            const container = document.querySelector('.estimate__content').getBoundingClientRect()
                            const totalW = container.x + container.width

                            if(panel.x + panel.width + 10 > totalW) {
                                el.value.style = 'transform: translateX(-100%); right: auto; left: -0.25rem; opacity: 1;'
                            }else {
                                el.value.style = 'transform: translateX(100%); right: -0.25rem; left: auto; opacity: 1;'
                            }
                        }
                    },
                    {
                        threshold: 0.01,
                    }
                );
            observer.observe(el.value);
        })

        return {
            el
        }
    }

})
</script>

<template>
    <div ref="el" class="tw-absolute tw-bg-gray-100  tw-top-0 tw-transform tw-translate-x-full tw-opacity-0 tw-shadow tw-border tw-border-solid tw-border-gray-300 tw-rounded-xs">
        <slot></slot>
    </div>
</template>