import { AJAX_DELETE, AJAX_GET, AJAX_POST } from '../helpers/ajax'
import router from '@/router'

const newOrderActions = [
    {
        "name": "Add Item",
        "available_on": "page",
        "type": "primary",
        "icon": "fas fa-plus",
        "event": "additemBtnClick"
    },
    {
        "name": "Add Accessory",
        "available_on": "page",
        "type": "primary",
        "icon": "fas fa-plus",
        "event": "addaccessoryBtnClick"
    },
]

const newOrderSummary = {
    "Product Cost": {
        "amount": "0.00",
        "visible": true,
        "name": "Product Cost"
    },
    "Charges": {
        "amount": 0,
        "name": "Charges",
        "visible": true
    },
    "Subtotal": {
        "amount": "0.00",
        "name": "Subtotal",
        "visible": true
    },
    "GST": {
        "amount": "0.00",
        "visible": true,
        "name": "GST"
    },
    "Total": {
        "amount": "0.00",
        "visible": true,
        "name": "Total"
    }
}

export default {

    async getData(id, platformType, type = '') {

        if(id === 'new' && type === 'actions'){
            return newOrderActions
        }

        if(id === 'new' && type === 'locations') {
            return []
        }

        if(id === 'new' && type === 'summary') {
            return newOrderSummary
        }

        if(id === 'new' && type === 'header') {
            return {
                "state": "Draft",
                "status": "Draft",
                "number": 'NEW',
                "date": new Date(),
                "type": "dealer",
                "reference": null,
                "parent_id": 'NEW'
              }
        }

        let url = `${platformType}/${id}`
        if(type) url += `/${type}`
        return await AJAX_GET(url)
    },
    async acceptEstimate(id, payload) {
        return await AJAX_POST(`/my-estimates/actions/${id}/accept`)
    },
    async rejectEstimate(id, payload) {
        return await AJAX_POST(`/my-estimates/actions/${id}/reject`)
    },
    async cloneEstimate(area, id, payload) {
        return await AJAX_POST(`/${area}/${id}/clone`)
    },
    async deleteEstimate(area, id) {
        return await AJAX_DELETE(`/${area}/${id}`)
    },
    async getSummary(id, type) {
        return await AJAX_GET(`${this.getContainer()}/${type}/${id}/locations`)
    },
    async getLocationItems(id, type, location) {
        const encodedLocationName = encodeURIComponent(location);
        return await AJAX_GET(`${this.getContainer()}/${type}/${id}/locations/${encodedLocationName}`)
    },
    async getItemAcessories(id, type) {
        return await AJAX_GET(`${this.getContainer('')}${type}/position/${id}/accessories`)
    },
    async setSortLocations(id, type, payload) {
        return await AJAX_POST(`${this.getContainer()}/${type}/${id}/sort`, payload)
    },
    async getSpecification(id, type = 'customer') {
        return await AJAX_GET(`${this.getContainer()}/${type}/${id}/specification`)
    },
    async getSpecificationNew(id, type = 'customer') {
        const res = await AJAX_GET(`${this.getContainer()}/${type}/${id}/specification`)
        const mainItems = res?.data?.mainItems ?? []

        const transformedItems = {}

        mainItems.forEach((item, index) => {
            transformedItems[index] = item;
        });
        res.data.mainItems = transformedItems
        return res
    },
    async removeItem(type = 'position', id,prefix = null) {
        return await AJAX_DELETE(`${this.getContainer(prefix)}/${type}/${id}/delete`)
    },
    getContainer(prefix = null) {
        const route = router.options.history.location
        if(prefix)   return `/${prefix}`
        if((route.includes('orders') || route.includes('job'))) return ''
        return ''
    }
}
