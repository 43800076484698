<template>
    <template v-if="menuButtons.length">
        <button class="btn tw-gap-2 tw-relative tw-pointer-events-none" :class="[`btn_${data.type}`]" :disabled="disabled || data.loading" >
            <div class="tw-flex tw-pointer-events-auto tw-gap-2" @click="openPanel">
                <Spinner class="btn__loader" color="var(--PrimaryButtonTextColour)" v-if="data.loading" />
                <svg-icon-qouting :data="data.icon" :color="data.type !== 'outline'? 'white': undefined" v-else-if="data.icon" class="btn__icon"/>
                <div class="btn__title" v-if="data.title">{{ data.title }}</div>
                <div class="hidden">
                    <slot ></slot>
                </div>
            </div>

            <div v-if="open" v-click-outside="() => open = false" class="tw-pointer-events-auto tw-p-2 menu-panel tw-absolute tw--right-1 tw-bottom-0 tw-transform tw-translate-x-full tw-absolute tw-bg-white tw-shadow tw-mt-2 tw-py-2 tw-right-0 tw-shadow-sm tw-text-sm tw-w-max tw-divide-y-4 tw-divide-solid tw-divide-gray-400 tw-flex tw-flex-col tw-gap-1 tw-shadow tw-border tw-border-solid tw-border-gray-100 tw-rounded">
                <button v-for="(btn, index) in menuButtons" :key="index" class="btn menu-btn-item tw-gap-2 !tw-w-full no-border hover:tw-bg-gray-200 tw-transition-all" :class="[`btn_${btn.type}`]" :disabled="disabled || btn.loading" @click="($event) => updLoading(btn, $event, true)">
                    <Spinner class="btn__loader" color="var(--PrimaryButtonTextColour)" v-if="btn.loading" />
                    <svg-icon-qouting :data="btn.icon" :color="btn.type !== 'outline'? 'white': undefined" v-else-if="btn.icon" class="btn__icon"/>
                    <div class="btn__title" v-if="btn.title">{{ btn.title }}</div>
                    <div class="hidden">
                        <slot ></slot>
                    </div>
                </button>
            </div>
        </button>
    </template>


    <button v-else class="btn tw-gap-2" :class="[`btn_${data.type}`]" :disabled="disabled || data.loading" @click="($event) => updLoading(data, $event, false)">
        <Spinner class="btn__loader" color="var(--PrimaryButtonTextColour)" v-if="data.loading" />
        <svg-icon-qouting :data="data.icon" color="var(--PrimaryButtonTextColour)" v-else-if="data.icon" class="btn__icon"/>
        <div class="btn__title" v-if="data.title">{{ data.title }}</div>
        <div class="hidden">
            <slot ></slot>
        </div>
    </button>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import Spinner from '../elements/Loader.vue'
import DotActions from '../../components/estimate/item/location/DotActions.vue'
import _ from 'lodash'
import vClickOutside from 'click-outside-vue3'

export default({
    props: ['data', 'disabled'],
    components: {
        Spinner,
        DotActions,
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    setup(props, { emit }) {
        const router = useRouter()

        function updLoading(data, e, prevent) {
            if(data.href) router.push(data.href)
            else if('loading' in data) data.loading = !data.loading
            if(data.event) emit(`${data.event}`)
            if(prevent) {
                e.preventDefault()
                e.stopPropagation()
                e.stopImmediatePropagation()
            }
        }

        const menuButtons = computed(() => {
            let btns = _.get(props, 'data.buttons', [])
            return btns.map(b => {
                return {
                    title: b.name || b.title,
                    className: b.name && b.name.split(' ').join('-').toLowerCase(),
                    ...b
                }
            })
        })

        const open = ref(false)

        const openPanel = (e) => {
            open.value = !open.value
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()
        }
        return {
            updLoading,
            menuButtons,
            open,
            openPanel,
        }
    },
})
</script>

<style scoped lang="scss">

.\!tw-w-full {
    width: 100%!important;
}

.no-border {
    border: none!important;
}

.menu-panel {
    z-index: 1;
}

.btn {
    padding: 10px;
    border-radius: 2px;
    margin: 0;
    border: none;
    box-shadow: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 32px;

    &.menu-btn-item {
        padding-left: 10px!important;
        padding-right: 10px!important;
        justify-content: flex-start;
    }

    &_primary {
        background: var(--PrimaryButtonColour);
        color: var(--PrimaryButtonTextColour);
    }
    &_outline {
        cursor: pointer;
        // border: 3px solid var(--SecondaryTextColour);
        border: 3px solid var(--PrimaryButtonTextColour);
        background: none;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        text-transform: uppercase;
        // color: #FFFFFF;
        color: var(--PrimaryButtonTextColour);
        display: flex;
        align-items: center;
    }
    &__icon, &__loader {
        // margin-right: 9px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__loader {
        max-width: 15px;
        color: #fff;
    }

    &__title {
        font-weight: bold;
        font-size: 14px;
        // line-height: 70%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        white-space: nowrap;
    }
    .hidden {
        position: absolute;
        cursor: pointer;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}
</style>
