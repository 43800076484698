<template>
    <div v-click-outside="() => showDropdown = false" class="top__actions">
        <div class="top__actions-container" @click="showDropdown = !showDropdown">
            <svg-icon-qouting data="fas fa-ellipsis-h" />
        </div>
        <div class="actions__dropdown" v-if="showDropdown">
             <!-- @click="$emit(`${cloneAction.event}`), showDropdown = !showDropdown" -->
            <div class="actions__item tw-relative tw-flex tw-justify-end tw-gap-2 tw-cursor-auto">
                <div @click="$emit(`${cloneAction.event}`), showDropdown = !showDropdown">
                    <svg-icon-qouting :data="cloneAction.icon" />
                    <span>{{ cloneAction.title }}</span>
                </div>
                <button @click="showCloneSubmenu = true" type="button" class="tw-border-none tw-cursor-pointer tw-px-0 tw-bg-transaprent hover:tw-opacity-80 tw-transition-all">
                    <svg-icon-qouting data="fas fa-ellipsis-h" />
                </button>

        
                <clone-sub-menu v-if="showCloneSubmenu">
                    <div class="tw-flex tw-gap-1 tw-p-2" v-click-outside="() => showCloneSubmenu = false">
                        <input v-model="cloneNumber" type="number" max="99" min="1" class="tw-w-10 tw-border-none tw-ring-1 tw-ring-gray-300"/>
                        <div class="clone-btn-multiple" @click="cloneMultiple">
                            <svg-icon-qouting data="fas fa-clone" style="font-size: 11px;"/>
                            Clone
                        </div>
                    </div>
                </clone-sub-menu>
            </div>

            <div class="actions__item" 
                v-for="item in actions" 
                :key="item.event" 
                @click="$emit(`${item.event}`), showDropdown = !showDropdown"
            >
                <svg-icon-qouting :data="item.icon" />
                <span>{{ item.title }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '../../../form/Button.vue'
import Options from './Options.vue'
import { ref } from 'vue'
import vClickOutside from 'click-outside-vue3'
import CloneSubMenu from './CloneSubMenu.vue'

export default ({
    props: ['data'],
    directives: {
        clickOutside: vClickOutside.directive
    },
    components: {
        Button,
        Options,
        CloneSubMenu,
    },
    setup(props, {emit}) {

        const cloneAction = {
                title: 'Clone',
                event: 'clone',
                icon: 'fas fa-clone',
        }

        const actions = [
            {
                title: 'Delete',
                event: 'remove',
                icon: 'fas fa-trash-alt',
            }
        ]
        const showDropdown = ref(false)
        const showCloneSubmenu = ref(false)
        const cloneNumber = ref(1)

        const cloneMultiple = () => {
            emit('cloneMultipleTimes', cloneNumber.value)
        }
        
        return {
            actions,
            showDropdown,
            cloneAction,
            showCloneSubmenu,
            cloneMultiple,
            cloneNumber,
        }
    },
})
</script>


<style scoped lang="scss">

.clone-btn-multiple {
    cursor: pointer;
    font-size: 13px;
    line-height: 100%;
    letter-spacing: 0.02em;
    white-space: nowrap;
    padding: 5px 10px;
    background: #59B692;
    border-radius: 2px;
    color: white;
}

.top {
    &__actions {
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.15), rgba(11, 53, 83, 0.15)), #FFFFFF;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        color: #000;
        &-container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.actions {
    &__dropdown {
        position: absolute;
        right: 0;
        bottom: -100%;
        transform: translateY(55%);
        min-width: 142px;
        padding: 10px 0;
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.05), rgba(11, 53, 83, 0.05)), #FFFFFF;
        border: 1px solid rgba(11, 53, 83, 0.2);
        box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.1);
        z-index: 10;
    }
    &__item {
        margin-bottom: 10px;
        text-align: right;
        text-transform: uppercase;
        padding: 0 10px;
        font-weight: 600;
        cursor: pointer;
        &:last-of-type {
            margin-bottom: 0;
        }
        span {
            display: inline-block;
            margin-left: 5px;
        }
    }

    
}

</style>